<template>
  <div id="generate-invoices">
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'GenerateReclamationInvoice'">
      <template v-slot:right-end>
      </template>
    </breadcrumb-base>
    <div class="content-center">
      <div class="vx-row w-full mb-base flex">
        <div class="md:w-1/5 w-full flex">
          <vx-card >
            <div class="vx-row">
              <div class="vx-col w-full mt-2 mb-6">
                <p style="font-size: 10px; font-weight: bold;">{{ $t('FromDate') | title }}</p>
                <datepicker :placeholder="$t('FromDate')+ '*'" v-model="fromDate"
                            @input="onFromToDateChanged"
                            :disabled-dates="disabledFromDates"></datepicker>
                <div class="py-2"></div>
                <p style="font-size: 10px; font-weight: bold;">{{ $t('ToDate') | title }}</p>
                <datepicker :placeholder="$t('ToDate') + '*'" v-model="toDate"
                            @input="onToDateChanged"
                            :disabled-dates="disabledDates"></datepicker>


                <div class="vx-col mt-3">

                  <label style="font-size: 12px; font-weight: bold">{{ $t('Manager') }}
                    <span
                      @click="selectedManager = null;onFilterChanged()"
                      class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
                  </label>
                  <vs-select
                    autocomplete
                    class="pr-2 w-full pb-3"
                    v-model="selectedManager"
                    @input="onFilterChanged(-1)"
                    :placeholder="$t('Manager')"
                  >
                    <vs-select-item key="all_referer" :value="null" :text="$t('All')"/>
                    <vs-select-item :key="index" :value="customer" :text="customer.user_name"
                                    v-for="(customer,index) in usersRaw.manager_accounts"/>
                  </vs-select>
                </div>

                <div class="vx-col mt-3">

                  <label style="font-size: 12px; font-weight: bold">{{ $t('Staff') }}
                    <span
                      @click="selectedReferer = null;onFilterChanged()"
                      class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
                  </label>
                  <vs-select
                    autocomplete
                    class="pr-2 w-full pb-3"
                    v-model="selectedReferer"
                    @input="onFilterChanged"
                    :placeholder="$t('Staff')"
                  >
                    <vs-select-item key="all_referer" :value="null" :text="$t('All')"/>
                    <vs-select-item :key="index" :value="customer.user_id" :text="customer.user_name"
                                    v-for="(customer,index) in staffAccounts"/>
                  </vs-select>
                </div>


                <div v-if="customersList.length > 0">
                  <div class="py-2"></div>
                  <p style="font-size: 10px; font-weight: bold;">{{ $t('InvoiceDate') | title }}</p>
                  <datepicker :placeholder="$t('InvoiceDate')" v-model="invoiceDate"
                              :disabled-dates="disabledInvoiceDates"></datepicker>
                  <div class="py-2"></div>
                  <div class="w-full mb-1">
                    <vs-input v-validate="''"
                              validate-on="change"
                              name="tax"
                              type="number"
                              class="w-full" autofocus
                              :label="$t('Tax') + ' %'"
                              :placeholder="$t('Tax') + ' %'" v-model="tax"/>

                    <span class="text-danger text-sm">{{ errors.first('tax') }}</span>
                  </div>
                  <div class="py-2"></div>
                  <div class=" w-full">
                    <p style="font-size: 10px; font-weight: bold;">{{ $t('Remarks') | title }}</p>
                    <vs-textarea :placeholder="$t('Remarks')"
                                 class="p-2"
                                 v-model="remarks"/>
                  </div>


                  <div class="py-3"></div>
                  <vs-button class="w-full" color="primary"
                             :disabled="!showOptions"
                             @click="generateInvoice(1)">{{ $t('GenerateInvoices') }}
                  </vs-button>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
        <div class="md:w-4/5 w-full">
          <p class="py-36 text-2xl w-full text-center" v-if="fromDate && toDate && customersList.length === 0">
            {{ $t('NoCustomers') }}
          </p>
          <vs-table-modified
            class="w-full ml-3 custom-table"
            v-show="customersList.length > 0" ref="table"
                             :data="customersList"
          >

            <template slot="thead">
              <vs-th>
                <vs-checkbox @input="onCustomerCheckedAll" v-model="checkedAll" ></vs-checkbox>
              </vs-th>
              <vs-th >{{$t('CustomerID')}}</vs-th>
              <vs-th>{{$t('Organisation')}}</vs-th>
<!--              <vs-th>{{$t('LastInvoiceDate')}}</vs-th>-->
<!--              <vs-th>{{ $t('LastInvoiceValue') }}</vs-th>-->
            </template>


            <template slot-scope="{data}">
              <tbody>
              <vs-tr :data="tr"
                     :key="tr.customer_id"
                     v-for="(tr, indextr) in data">

                <vs-td>
                  <vs-checkbox
                    @input="onCustomerChecked"
                    v-model="customersSelections"
                    :vs-value="tr.customer_id.toString()"></vs-checkbox>
                </vs-td>

                <vs-td>
                  <div class="vs-col">
                    <p>{{ tr.customer_id}}</p>
                    <p class="product-name font-medium truncate">{{ tr.organisation | capitalize }}</p>
                  </div>
                </vs-td>
                <vs-td>
                  <div class="vs-col">
                    <p class="truncate">{{ tr.organisation_first_name }} {{ tr.organisation_last_name }}</p>
                    <p style="font-size: 11px;">
                      {{ tr.address }}<span v-if="tr.house_no"> {{ tr.house_no }}</span>
                    </p>
                    <p style="font-size: 11px;">
                      {{ tr.postal }} {{ tr.city }}
                    </p>
                  </div>
                </vs-td>
<!--                <vs-td>-->
<!--                  <p>{{ tr.last_invoice_date | onlyDateFormat}}</p>-->
<!--                </vs-td>-->
<!--                <vs-td>-->
<!--                  <p>{{ tr.last_invoice_value | germanNumberFormat}}</p>-->
<!--                </vs-td>-->
              </vs-tr>
              </tbody>
            </template>
          </vs-table-modified>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicCustomersDropDown from '@/views/pages/superadmin/customer/DynamicCustomersDropDown'
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'

export default {
  name: 'GenerateInvoice',

  components: {
    DynamicCustomersDropDown,
    Datepicker,
  },
  data() {
    return {
      checkedAll: false,
      tax: '19',
      fromDate: null,
      toDate: null,
      invoiceDate: null,
      remarks: '',
      customersList: [],
      customersSelections: [],
      showOptions: false,
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'Generate Reclamation Invoice', i18n: 'GenerateReclamationInvoice', active: true},
      ],
      selectedManager: null,
      selectedReferer: null,
    }
  },
  computed: {
    validateForm() {
      return this.fromDate != null && this.toDate != null
    },
    disabledDates() {
      if (!this.fromDate) {
        return {
          from: new Date(),}
      }
      return {
        to: this.fromDate,
        from: new Date(),
      }
    },
    disabledFromDates() {
      return {
        from: new Date(),
      }
    },
    disabledInvoiceDates() {
      if (!this.toDate) {
        return {}
      }
      return {
        to: this.toDate,
        from: new Date(),
      }
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    usersRaw() {
      return this.$store.state.user.usersRaw || {}
    },
    users() {
      return this.$store.state.user.users
    },
    staffAccounts() {
      if (this.selectedManager != null) {
        return this.selectedManager.staff_accounts
      } else {
        return this.usersRaw.staff_accounts;
      }
    },
  },
  props: {},
  methods: {
    onFilterChanged(resetRefer) {
      if (resetRefer === -1) {
        this.selectedReferer = null
      }
      this.getInvoiceCustomers();
    },
    onCustomerChecked(value) {
      if(this.customersSelections.length > 0) {
        this.showOptions = true;
      } else {
        this.showOptions = false;
      }
      this.checkedAll = this.customersSelections.length === this.customersList.length
      // this.updateDiscountsFromSlab()
    },
    onCustomerCheckedAll(value) {
      if (value) {
        const obj = []
        this.customersList.forEach((k) => {
          obj.push(k.customer_id.toString())
        })
        this.customersSelections = obj
      } else {
        this.customersSelections = []
      }
      this.onCustomerChecked();
    },
    onCustomerChanged(c) {
      this.customer = c
    },
    clearAll() {
      this.customer = null
      this.tax = '19'
      this.fromDate = null
      this.toDate = null
      this.invoiceDate = null
      this.checkedAll = false
      this.customersSelections = [];
      this.customersList = []
      this.showOptions = false

    },
    onFromToDateChanged() {
      this.getInvoiceCustomers();
    },
    onToDateChanged() {
      this.invoiceDate = this.toDate;
      this.getInvoiceCustomers();
    },
    getInvoiceCustomers() {
      if (!this.fromDate) {
        return;
      }
      if (!this.toDate) {
        return;
      }
      const payload = {
        from_date: moment(this.fromDate).format('yyyy-MM-DD'),
        to_date: moment(this.toDate).format('yyyy-MM-DD'),
      }

      if (this.selectedManager) {
        payload.refer_id = this.selectedManager.user_id
      }
      if (this.selectedReferer) {
        payload.refer_id = this.selectedReferer
      }

      this.$vs.loading()
      this.$store.dispatch('invoice/getReclamationInvoiceCustomers', {payload})
        .then((data) => {
          this.$vs.loading.close()
          this.customersList = data.customers
        })
        .catch((error) => {
          this.$vs.loading.close()
          let msg = ''
          try {
            try {
              msg = error.data.message
            } catch (e) {
              msg = error.response.data.message
            }
          } catch (e) {
            msg = ''
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    generateInvoice(type) {
      if (this.customersSelections == null || this.customersSelections.length == 0) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please select the customer',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      const payload = {
        from_date: moment(this.fromDate).format('yyyy-MM-DD'),
        to_date: moment(this.toDate).format('yyyy-MM-DD'),
        customer_ids: this.customersSelections.join(","),
        remarks: this.remarks,
      }

      if (this.invoiceDate) {
        payload['invoice_date'] = moment(this.invoiceDate).format('yyyy-MM-DD')
      }
      if (this.tax) {
        payload['tax_value'] = this.tax
      }

      this.$vs.loading()
      this.$store.dispatch('invoice/generateCustomersReclamationInvoices', {payload})
        .then((data) => {
          this.$vs.loading.close()
          if (data.message_type == 1) {
            try {
              let sC = data.success_customers.join(", ")
              let fC = data.failed_customers.join(", ")
              this.$vs.notify({
                position: 'top-center',
                title: 'Success',
                text: `Invoice Generated ${sC}`,
                iconPack: 'feather',
                icon: 'icon-check-circle',
                color: 'success',
              })
              if (fC) {
                this.$vs.notify({
                  position: 'top-center',
                  title: 'Failed',
                  text: `Invoice Failed ${fC}`,
                  iconPack: 'feather',
                  icon: 'icon-check-close',
                  color: 'danger',
                })
              }
            } catch (er) {

            }
            this.clearAll();
          }
        })
        .catch((error) => {
          this.$vs.loading.close()
          let msg = ''
          try {
            try {
              msg = error.data.message
            } catch (e) {
              msg = error.response.data.message
            }
          } catch (e) {
            msg = ''
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    loadReferer() {
      this.$vs.loading()
      this.$store.dispatch('user/fetchUsers', {})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((err) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  mounted() {
    if(this.activeUserInfo.internal_role != 0) {
      this.$router.push('/')
      return;
    }
    this.toDate = moment(new Date()).subtract(1, 'days').toDate();
    this.invoiceDate = moment(new Date()).subtract(1, 'days').toDate();
    this.loadReferer();
  },
}
</script>

<style scoped lang="scss">
#generate-invoices {
  .custom-table {
    td {
      color: #515151;
    }
  }
}
</style>

